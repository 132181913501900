import React, { useContext } from "react";
import { LightboxContext } from "../contexts/LightboxProvider";
import ReactTooltip from "react-tooltip";
import Placeholder from "./Placeholder";
import "../style.scss";

const Lightbox = () => {
  const {
    isLightboxOpen,
    setIsLightboxOpen,
    src,
    setSrc,
    description,
    setDescription,
  } = useContext(LightboxContext);

  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLightboxOpen(false);
    setSrc("");
    setDescription("");
  };

  return (
    <div
      className={`lightbox ${isLightboxOpen ? "" : "--hidden"}`}
      onClick={handleClick}
    >
      <div className="lightbox-img-container">
        <img src={src} />
        <Placeholder />
      </div>
      <div className="lightbox-description">{description}</div>
    </div>
  );
};

export default Lightbox;
