import React, { createContext, useState, FC } from "react";

interface BGLoadedContextState {
  isBGLoaded: boolean;
  setIsBGLoaded(isLoaded: boolean): void;
}

const initialBGLoadedState: BGLoadedContextState = {
  isBGLoaded: false,
  setIsBGLoaded: () => null,
};

export const BGLoadedContext =
  createContext<BGLoadedContextState>(initialBGLoadedState);

const BGLoadedProvider: FC = ({ children }) => {
  const [isBGLoaded, setIsBGLoaded] = useState(initialBGLoadedState.isBGLoaded);

  return (
    <BGLoadedContext.Provider value={{ isBGLoaded, setIsBGLoaded }}>
      {children}
    </BGLoadedContext.Provider>
  );
};

export default BGLoadedProvider;
