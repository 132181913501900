import React, { useRef, useState } from "react";
import "../style.scss";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type DataProps = {
  allContentfulNavigation: {
    edges: [
      {
        node: {
          pages: {
            references: [
              {
                internal: {
                  type: string;
                };
                navigationName: string;
                navigationSymbol: string;
              }
            ];
          };
        };
      }
    ];
  };
};

const Navigation = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1250));
  const [showMenu, setShowMenu] = useState(false);
  const query: DataProps = useStaticQuery(graphql`
    query {
      allContentfulNavigation {
        edges {
          node {
            pages {
              references {
                ... on ContentfulPortfolioPage {
                  internal {
                    type
                  }
                  navigationName
                  navigationSymbol
                }
                ... on ContentfulHomePage {
                  internal {
                    type
                  }
                  navigationName
                  navigationSymbol
                }
              }
            }
          }
        }
      }
    }
  `);
  const queryRefs =
    query.allContentfulNavigation.edges[0].node.pages.references;
  const menuIcon = useRef<HTMLDivElement>(null);
  const url = typeof window !== "undefined" ? window.location.href : "";
  const slug =
    typeof window !== "undefined" ? new URL(url).pathname.match(/[^\/]+/g) : [];

  const handleToggle = (/*e: React.MouseEvent*/) => {
    //const target = e.currentTarget as HTMLDivElement;
    //target.classList.toggle("change");
    // links now trigger handleToggle, therefore we can't rely on event anymore
    if (menuIcon.current) menuIcon.current.classList.toggle("change");
    setShowMenu(!showMenu);
  };

  const rightDiagonal = (
    <svg height="40" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="0" x2="20" y2="40" stroke="#9ddac4" strokeWidth={2} />
    </svg>
  );

  const leftDiagonal = (
    <svg height="40" xmlns="http://www.w3.org/2000/svg">
      <line x1="20" y1="0" x2="1" y2="40" stroke="#9ddac4" strokeWidth={2} />
    </svg>
  );

  return (
    <div className="nav-wrapper">
      <div className="menu-wrapper">
        <div className="menu-icon" ref={menuIcon} onClick={handleToggle}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <nav className={matches ? (showMenu ? "--show" : "--hide") : ""}>
        {queryRefs.map((ref, index) => {
          let oddPadding = "0";
          if (index % 2 > 0) oddPadding = "30px";

          if (ref.internal.type === "ContentfulHomePage") {
            return (
              <React.Fragment key={index}>
                <Link
                  to="/"
                  style={{ paddingLeft: `${oddPadding}` }}
                  onClick={handleToggle}
                >
                  <div
                    className={
                      !slug ? "nav-item-wrapper-lit" : "nav-item-wrapper"
                    }
                  >
                    <span className="nav-symbol">{ref.navigationSymbol}</span>
                    <span className="nav-name">{ref.navigationName}</span>
                  </div>
                </Link>
                {rightDiagonal}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={index}>
              <Link
                to={`/${ref.navigationName.toLowerCase()}`}
                style={{ paddingLeft: `${oddPadding}` }}
                onClick={handleToggle}
              >
                <div
                  className={
                    slug && slug[0] === ref.navigationName.toLowerCase()
                      ? "nav-item-wrapper-lit"
                      : "nav-item-wrapper"
                  }
                >
                  <span className="nav-symbol">{ref.navigationSymbol}</span>
                  <span className="nav-name">{ref.navigationName}</span>
                </div>
              </Link>
              {(index % 2 > 0 && leftDiagonal) ||
                (index !== queryRefs.length - 1 && rightDiagonal)}
            </React.Fragment>
          );
        })}
      </nav>
    </div>
  );
};

export default Navigation;
