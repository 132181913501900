import React, { createContext, useState, FC } from "react";

interface LightboxContextState {
  isLightboxOpen: boolean;
  setIsLightboxOpen(isOpen: boolean): void;
  src: string;
  setSrc(src: string): void;
  description: string;
  setDescription(desc: string): void;
}

const initialLightboxState: LightboxContextState = {
  isLightboxOpen: false,
  setIsLightboxOpen: () => null,
  src: "",
  setSrc: () => null,
  description: "",
  setDescription: () => null,
};

export const LightboxContext =
  createContext<LightboxContextState>(initialLightboxState);

const LightboxProvider: FC = ({ children }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(
    initialLightboxState.isLightboxOpen
  );
  const [src, setSrc] = useState(initialLightboxState.src);
  const [description, setDescription] = useState(
    initialLightboxState.description
  );

  return (
    <LightboxContext.Provider
      value={{
        isLightboxOpen,
        setIsLightboxOpen,
        src,
        setSrc,
        description,
        setDescription,
      }}
    >
      {children}
    </LightboxContext.Provider>
  );
};

export default LightboxProvider;
