import React, { FC } from "react";
import Helm from "../components/Helm";
import BackgroundVideo from "../components/BackgroundVideo";
import Navigation from "../components/Navigation";
import Lightbox from "../components/Lightbox";
import ReactTooltip from "react-tooltip";
import "../style.scss";

const RootLayout: FC = ({ children }) => {
  return (
    <>
      <Helm />
      <BackgroundVideo />
      <Navigation />
      {children}
      <Lightbox />
      <ReactTooltip
        className="tooltip"
        place="bottom"
        delayShow={25}
        textColor={"#fdfacd"}
        backgroundColor={"rgba(16, 18, 20)"}
        clickable={false}
        border={false}
      />
    </>
  );
};

export default RootLayout;
