import React from "react";
import "../style.scss";

const Placeholder = () => {
  return (
    <div className="placeholder">
      <div className="loader" />
    </div>
  );
};

export default Placeholder;
