const React = require("react");
import RootLayout from "./src/components/RootLayout";
import LightboxProvider from "./src/contexts/LightboxProvider";
import BGLoadedProvider from "./src/contexts/BGLoadedProvider";
import type { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <BGLoadedProvider>
      <LightboxProvider>{element}</LightboxProvider>
    </BGLoadedProvider>
  );
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <RootLayout>{element}</RootLayout>;
};

// https://github.com/gatsbyjs/gatsby/issues/23234
