import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

type DataProps = {
  allContentfulHomePage: {
    edges: [
      {
        node: {
          siteTitle: string;
          icon: {
            file: {
              url: string;
            };
          };
        };
      }
    ];
  };
};

const Helm = () => {
  const siteInfoQuery: DataProps = useStaticQuery(graphql`
    query {
      allContentfulHomePage {
        edges {
          node {
            siteTitle
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const siteInfoData = siteInfoQuery.allContentfulHomePage.edges[0].node;

  return (
    <Helmet>
      <html lang="en" />
      <title>{siteInfoData.siteTitle}</title>
      <link rel="icon" type="image/x-icon" href={siteInfoData.icon.file.url} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap"
        rel="stylesheet"
      />
      <script
        src="https://kit.fontawesome.com/e09d9d3a54.js"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
};

export default Helm;
